<!--
 * @Author: liyu
 * @Date: 2020/8/31
 * @Description: 优化显示交互逻辑
 -->
<template>
  <div class="dialog-contain">
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogItineraryVisible"
    >
      <div
        class="itinerary-body-contain"
        :style="chooseItinerary?chooseItineraryStyle:''"
      >
        <span class="title">{{ title }}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{fill:'#A9B5C6'}"
          ></en-icon>
        </div>

        <div class="content-contain">
          <!-- 已有行程 -->
          <template v-if="chooseItinerary">
            <div v-if="tableData.length > 0" class="choose-itinerary-contain">
              <div v-for="item in tableData" :key="item.id" class="row-contain" @click="rowClick(item)">
                <en-icon
                  name="lidianriqi"
                  size="40px"
                  class="icon"
                />
                <span class="date text-common">{{ item.tripStartDate + " / " + item.tripStartDate }}</span>
                <span class="desc text-common">{{ item.tripReason }}</span>
                <span class="number text-common">{{ item.tripNo }}</span>
                <enTripBillStatus class="status" :status="Number(item.tripStatus)"></enTripBillStatus>
              </div>
            </div>
            <en-result v-else class="no-data-result" type="NoData"></en-result>
          </template>

          <!-- 选择行程or新建行程 -->
          <template v-else>
            <div class="content-box-contain">
              <div
                class="choose-item-contain"
                @click="chooseItineraryClick"
              >
                <div class="choose-contain">
                  <en-icon
                    name="yiyouhangcheng1"
                    class="choose-image-contain"
                  ></en-icon>
                </div>
                <span class="bottom-text">选择已有行程</span>
              </div>

              <div
                class="choose-item-contain"
                @click="newItineraryClick"
              >
                <div class="choose-contain">
                  <en-icon
                    name="xinjianhangchengdan"
                    class="choose-image-contain"
                  ></en-icon>
                </div>
                <span class="bottom-text">新建行程</span>
              </div>

              <!--
              <div
                v-if="isNewNode"
                class="choose-item-contain"
                @click="buGuanLianClick"
              >
                <div class="choose-contain">
                  <en-icon
                    name="buguanlian"
                    class="choose-image-contain"
                  ></en-icon>
                </div>
                <span class="bottom-text">不关联了</span>
              </div>
              -->

            </div>
          </template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "en-js";
import { tripService } from "@/api";
import enTripBillStatus from "../en-trip-bill-status";

export default {
  name: "EnItinerary",
  components: {
    enTripBillStatus
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    tripDataList: {
      type: Array,
      default: null
    },
    onlyChooseItinerary: {
      type: Boolean,
      default: false
    },
    isNewNode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: "关联行程",
      tripId: "",
      dialogItineraryVisible: false,
      chooseItinerary: false,
      chooseItineraryStyle: "height:536px;padding-left:0px;padding-right:0px;",
      tableData: [],
      loading: false
    };
  },
  watch: {
    dialogVisible(val) {
      this.dialogItineraryVisible = val;
    },
    dialogItineraryVisible(val) {
      if (val !== this.dialogVisible) {
        this.$emit("update:dialogVisible", val);
      }
    }
  },
  mounted() {
    this.dialogItineraryVisible = this.dialogVisible;
    this.chooseItinerary = this.onlyChooseItinerary;
    if (this.onlyChooseItinerary) {
      this.tableData = [...this.tripDataList];
    }
  },
  methods: {
    // 请求可关联行程
    @request(true)
    async requestCanTripForRelationList() {
      this.tableData = await tripService.queryTripForRelation();
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    closeClick() {
      if (this.chooseItinerary) { // 如果是选择状态，则回退选择状态
        this.chooseItinerary = false;
      } else {
        this.dialogItineraryVisible = false;
      }
    },
    async chooseItineraryClick() {
      await this.requestCanTripForRelationList();
      this.chooseItinerary = true;
      this.loading = true;
      this.title = "已有行程";
      tripService.queryTripForRelation().then((response) => {
        this.tableData = response;
        this.loading = false;
      }).catch((error) => {
        this.$message.error(error.message);
        this.loading = false;
      });
    },
    newItineraryClick() {
      this.dialogItineraryVisible = false;
      this.$emit("selectCreateNewTrip");
    },
    buGuanLianClick() {
      this.dialogItineraryVisible = false;
      this.$emit("selectUnrelatedTrip");
    },
    rowClick(row) {
      this.dialogItineraryVisible = false;
      this.$emit("selectTripData", row);
    }
  }
};
</script>

<style lang="scss" scoped>
.itinerary-body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 700px;
  padding-left: 40px;
  padding-right: 40px;
  height: 400px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
  .content-contain {
    height: 100%;
    .choose-itinerary-contain {
      overflow: auto;
      margin-top: 49px;
      height: calc(100% - 49px - 20px);
      margin-bottom: 20px;
      .row-contain {
        position: relative;
        height: 80px;
        width: calc(100% - 40px);
        margin: 6px 20px;
        background: #FFFFFF;
        border: 1px solid #e8ecf2;
        border-radius: 4px;
        .text-common {
          position: absolute;
          height: 12px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          line-height: 12px;
        }
        .icon {
          margin: 20px 0 20px 15px;
          width: 40px;
          height: 40px;
          padding: 10px;
          fill: white;
          background: rgba(70, 148, 223, 1);
          border-radius: 50%;
        }
        .date {
          top: 21px;
          left: 66px;
          color: #91a1b7;
          text-align: left;
        }
        .desc {
          top: 45px;
          left: 67px;
          right: 75px;
          color: #636c78;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .number {
          top: 21px;
          right: 15px;
          color: #91a1b7;
          text-align: right;
        }
        .status {
          position: absolute;
          top: 40px;
          right: 15px;
          width: auto;
          height: 26px;
        }
        &:hover {
          background-color: #F5F9FD;
        }
      }

      & /deep/ .el-table__row /deep/ td,
      & /deep/ .el-table__row /deep/ th {
        height: 92px;
        border-color: transparent;
      }
      & /deep/ .el-table::before {
        height: 0;
      }
      & /deep/ .el-table .cell {
        padding: 0;
      }
    }
    .no-data-result {
      margin-top: 120px;
    }
    .content-box-contain {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .choose-item-contain {
        width: 200px;
        height: 230px;
        margin: 40px;
        .choose-contain {
          margin-top: 25px;
          width: 100%;
          height: 180px;
          border: 1px solid #e8ecf2;
          border-radius: 4px;
          &:hover {
            background-color: #f5f9fd;
          }
        }
        .choose-image-contain {
          width: 130px;
          height: 125px;
          margin: 27px 34px;
        }
        .bottom-text {
          display: block;
          height: 12px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #333;
          line-height: 12px;
          text-align: center;
          margin-top: 12px;
        }
      }
    }
  }
}

.dialog-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>
