var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            "show-close": false,
            visible: _vm.dialogItineraryVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogItineraryVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "itinerary-body-contain",
              style: _vm.chooseItinerary ? _vm.chooseItineraryStyle : "",
            },
            [
              _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c(
                "div",
                { on: { click: _vm.closeClick } },
                [
                  _c("en-icon", {
                    staticClass: "close-icon",
                    style: { fill: "#A9B5C6" },
                    attrs: { name: "guanbi-danchuang", size: "14px" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-contain" },
                [
                  _vm.chooseItinerary
                    ? [
                        _vm.tableData.length > 0
                          ? _c(
                              "div",
                              { staticClass: "choose-itinerary-contain" },
                              _vm._l(_vm.tableData, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "row-contain",
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("en-icon", {
                                      staticClass: "icon",
                                      attrs: {
                                        name: "lidianriqi",
                                        size: "40px",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "date text-common" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.tripStartDate +
                                              " / " +
                                              item.tripStartDate
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "desc text-common" },
                                      [_vm._v(_vm._s(item.tripReason))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "number text-common" },
                                      [_vm._v(_vm._s(item.tripNo))]
                                    ),
                                    _c("enTripBillStatus", {
                                      staticClass: "status",
                                      attrs: {
                                        status: Number(item.tripStatus),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c("en-result", {
                              staticClass: "no-data-result",
                              attrs: { type: "NoData" },
                            }),
                      ]
                    : [
                        _c("div", { staticClass: "content-box-contain" }, [
                          _c(
                            "div",
                            {
                              staticClass: "choose-item-contain",
                              on: { click: _vm.chooseItineraryClick },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "choose-contain" },
                                [
                                  _c("en-icon", {
                                    staticClass: "choose-image-contain",
                                    attrs: { name: "yiyouhangcheng1" },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "bottom-text" }, [
                                _vm._v("选择已有行程"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "choose-item-contain",
                              on: { click: _vm.newItineraryClick },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "choose-contain" },
                                [
                                  _c("en-icon", {
                                    staticClass: "choose-image-contain",
                                    attrs: { name: "xinjianhangchengdan" },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "bottom-text" }, [
                                _vm._v("新建行程"),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }